// Colors
$primary-color: #00a5e4;
$secondary-color: #5952c0;
$orange-color: #fd7e14;
$green-color: #17c5a2;
$red-color: #FF4267;
$blue-color: #0092ff;
$grey-color-1: #f5f6fa;
$grey-color-2: #676d7c;
$grey-color-3: #a0a6b1;
$grey-color-4: #24282e;
$grey-color-5: #141617;
$grey-color-6: #e5eaef;
$white-color: #fff;
$black-color: #1a1c1e;

// Grey color (Light Theme)
$light-grey-100: #f4f5f3;
$light-grey-400: #a3a6ae;
$light-grey-500: #7b7f87;
$light-grey-700: #201f26;

// Grey color (Dark Theme)
$dark-grey-100: #ffffff;
$dark-grey-400: #a0a6b1;
$dark-grey-500: #25282d;
$dark-grey-700: #181a1b;

// Style
$line-color: $grey-color-6;

// bootstrap
$primary: $primary-color;
$warning: $orange-color;
$info: $secondary-color;
$success: $green-color;
$btn-font-size-lg: 16px;
$btn-padding-x: 16px;
$btn-padding-y: 8px;
$btn-border-radius: 8px;
